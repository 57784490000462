(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name competicio.newSeason
   * @description
   *
   */
  angular
  .module('neo.home.seasons.newSeason', []);
}());
